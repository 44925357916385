.policy-items{
    margin: 1px 0 1px 20px;
    list-style-type: disc;
}

.services .servicesBlock {
    background-color: #fff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.07);
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.services .servicesBlock__title {
    margin-bottom: 6rem;
    text-align: center;
}

.services__card {
    cursor: pointer;
}

.services__image {
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.services__image img {
    max-width: 100%;
    max-height: 100%;
}

.services__title {
    text-align: center;
}

.home-tab-wrapper .grid-section .section-tiles-wrapper .section-category-tile .tile-img {
    height: auto;
    width: 100%;
}

.grid-section .section-tiles-wrapper {
    display: grid;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.grid-section .section-tiles-wrapper .section-category-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    text-align: center;
    transition: all 0.25s ease-in-out;
}

.grid-section .section-tiles-wrapper .section-category-tile {
    cursor: pointer;
}

.grid-section .section-tiles-wrapper .section-category-tile .tile-img-tag {
    background-color: #fff;
    border: 1px solid rgba(216, 216, 216, 0.5);
    border-radius: 15px;
    margin: 0 auto 10px;
    padding: 14px 10px;
    position: relative;
    width: 60px;
}

.grid-section .section-tiles-wrapper .section-category-tile .tile-img-tag .tile-tag {
    background-color: #1277ee;
    border-radius: 2px;
    color: #fff;
    font-family: sspBold;
    font-size: 0.714em;
    padding: 1px 5px;
    position: absolute;
    right: -11px;
    text-align: center;
    top: -5px;
}

.grid-section .section-tiles-wrapper .section-category-tile .tile-img-tag .tile-tag:before {
    border-color: #1277ee transparent transparent #1277ee;
    border-style: solid;
    border-width: 3px;
    bottom: -2px;
    box-sizing: border-box;
    color: #222;
    content: "";
    left: 0;
    position: absolute;
    width: 3px;
}

.grid-section .section-tiles-wrapper .section-category-tile .tile-type {
    color: #051441;
    font-family: sspRegular;
    font-size: 1em;
    line-height: 1.2em;
    margin-bottom: 2px;
    word-break: break-word;
}

.grid-section .section-tiles-wrapper .section-category-tile .tile-cb {
    color: #051441;
    font-family: sspBold;
    font-size: 0.857em;
    text-align: center;
    word-break: break-word;
}

.grid-section .section-tiles-wrapper .more-icon {
    cursor: pointer;
}

.grid-section .section-tiles-wrapper .more-icon .tile-img-tag {
    background-color: #fff;
    border: 1px solid rgba(216, 216, 216, 0.5);
    border-radius: 15px;
    height: 60px;
    margin: 0 auto 10px;
    position: relative;
    width: 60px;
}

.grid-section .section-tiles-wrapper .more-icon .tile-img-tag.expand span {
    top: calc(50% - 4px);
    transform: rotate(-135deg);
}

.grid-section .section-tiles-wrapper .more-icon .tile-img-tag span {
    border-bottom: 2px solid #f47647;
    border-radius: 2px;
    border-right: 2px solid #f47647;
    height: 12px;
    left: calc(50% - 6px);
    position: absolute;
    top: calc(50% - 8px);
    width: 12px;
    transform: rotate(45deg);
    transition: transform 0.5s ease-in-out;
}

.grid-section .section-tiles-wrapper .more-icon .tile-type {
    color: #5a6876;
    font-family: sspRegular;
    font-size: 1em;
    line-height: 1.2em;
    margin-bottom: 2px;
    text-transform: capitalize;
    word-break: break-word;
}

.home-tab-wrapper .grid-section .section-tiles-wrapper {
    grid-template-columns: repeat(5, 195px);
}

.loan-tab-wrapper .grid-section .section-tiles-wrapper .section-category-tile .tile-img-tag {
    padding: 8px 10px;
}

.loan-tab-wrapper .grid-section .section-tiles-wrapper .section-category-tile .tile-img-tag .tile-img {
    width: 40px;
}

.loan-tab-wrapper .grid-section .section-tiles-wrapper {
    grid-template-columns: repeat(7, 95px);
}

@media (max-width: 1200px) {
    .grid-section .section-tiles-wrapper {
        grid-template-columns: repeat(4, 150px);
    }
}

@media (max-width: 992px) {
    .grid-section .section-tiles-wrapper {
        grid-template-columns: repeat(3, 120px);
    }
}

@media (max-width: 768px) {
    .grid-section .section-tiles-wrapper {
        grid-template-columns: repeat(2, 150px);
    }
}

@media (max-width: 576px) {
    .grid-section .section-tiles-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
        justify-content: flex-start;
        overflow-x: auto;
        white-space: nowrap;
    }

    .grid-section .section-tiles-wrapper .section-category-tile {
        flex: 0 0 auto;
        margin-right: 10px;
    }

    .grid-section .section-tiles-wrapper .section-category-tile .tile-img-tag {
        width: 60px;
        height: auto;
        padding: 10px;
    }

    .grid-section .section-tiles-wrapper .section-category-tile .tile-img {
        max-width: 100%;
        height: auto;
    }
}
